import React,{Fragment} from 'react'
import { Link } from 'react-router-dom'

const ServiceContent = [
    {
        icon: "icon_31",
        title: 'Stock Data Analysis & Quantitative Trading',
        desc: `Our advanced analytics and machine learning techniques power our stock data analysis, while our quantitative trading models strategically enhance portfolio performance.`,
        arrow: 'icon_20',
        datadelay: '',
        dataAos: 'fade-right',
        className: ''
    }, {
        icon: "icon_32",
        title: 'Real Estate Data Analysis, Design & Construction Management',
        desc: `By analysing property data, market trends, and economic indicators, we identify potential investment opportunities.  Our team also designs and manages real estate projects based on these insights, ensuring that every aspect is tailored to align with market demands.`,
        arrow: 'icon_20',
        datadelay: '100',
        dataAos: 'fade-up',
        className: 'active'
    }, {
        icon: "icon_33",
        title: 'Quantitative Real Estate Trading',
        desc: `We employ our data analytics capabilities to create quantitative trading strategies for real estate, helping clients optimise the timing for selling or leasing properties to maximise returns and mitigate risks.`,
        arrow: 'icon_20',
        dataDelay: '',
        dataAos: 'fade-left',
        className: ''
    }
]
const FancyFeatureSeventeen = () => {
    return ( 
    <Fragment> 
      <div className="row justify-content-center pt-30">
          {ServiceContent.map((val, i) => (
              <div key={i} className="col-lg-4 col-md-6" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
                  <div
                      className={`block-style-twelve block-space mt-30 ${val.className}`}>
                      <div className="icon d-flex align-items-end"><img src={`images/icon/${val.icon}.svg`} alt=""/></div>
                      <h5 style={{paddingTop:10}}>
                          {val.title}
                      </h5>
                      <p>{val.desc}</p>
                      {/* <Link to="/service-details" className="tran3s more-btn"><img src="images/icon/icon_20.svg" alt=""/></Link> */}
                  </div>
                  {/* /.block-style-twelve */}
              </div>
          ))}

      </div> 
    </Fragment>
  )
}

export default FancyFeatureSeventeen