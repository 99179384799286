import React, {Fragment} from 'react';


const ProcessContent = [
    {
        num: '1',
        title: 'Real Estate Data Analysis',
        desc: `We delve deep into the specifics of the real estate market, applying advanced analytics to property data, market trends, location-specific factors, and economic indicators.  By processing and analysing these complex data sets, we identify potential investment opportunities and risks, allowing our clients to make informed decisions.`,
        fadeUp: 'fade-up',
        dataDelay: ''
    },
    {
        num: '2',
        title: 'Project Design and Construction Management',
        desc: `Our team of real estate experts not only analyse data but also design and manage real estate projects.  We work closely with architects, engineers, and contractors to design projects that align with market demands and trends.  Throughout the construction phase, we oversee all aspects of the process, ensuring projects are completed on time and within budget while adhering to the highest quality standards.`,
        fadeUp: 'fade-up',
        dataDelay: '150'
    },
    {
        num: '3',
        title: 'Quantitative Trading',
        desc: `Post-construction, we leverage our real estate data analytics to devise a quantitative trading strategy.  We evaluate the optimal time to sell or lease properties based on various factors, including market conditions, rental yields, and property appreciation.  Our quantitative trading strategies aim to maximise return on investment and mitigate potential risks.`,
        fadeUp: 'fade-up',
        dataDelay: '300'
    },
]

const FancyFeatureThree = () => {
    return (
        <Fragment>
            <div className="row justify-content-center gx-xxl-5">
                {ProcessContent.map((val, i)=>(
                    <div key={i} className="col-lg-4 col-sm-12 d-flex" data-aos={val.fadeUp} data-aos-delay={val.dataDelay}>
                    <div className="block-style-three mb-25">
                        <div className="numb">{val.num}</div>
                        <h6>{val.title}</h6>
                        <p>{val.desc}</p>
                    </div>
                    {/* /.block-style-three */}
                </div>
                ))}
            </div>
        </Fragment>
    )
}

export default FancyFeatureThree