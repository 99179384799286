import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {HashLink} from "react-router-hash-link"

const SocialContent = [
    {
        icon: 'fab fa-facebook-f',
        routerPath: '#'
    }, {
        icon: 'fab fa-twitter',
        routerPath: '#'
    }, {
        icon: 'fab fa-linkedin-in',
        routerPath: '#'
    }
];
const PageContent = [
    {
        name: 'Home',
        routerPath: '/#'
    }, {
        name: 'About Us',
        routerPath: '/#aboutus-hash'
    }, {
        name: 'Service',
        routerPath: '/#service-hash'
    }, {
        name: 'Investment Process',
        routerPath: '/#investmentprocess-hash'
    }, {
        name: 'Contact',
        routerPath: '/#contact-hash'
    }
];
const ServiceContent = [
    {
        name: 'Artificial Intelligence',
        routerPath: '/service-details'
    }, {
        name: 'Data Analytics',
        routerPath: '/service-details'
    }, {
        name: 'Data Visualization',
        routerPath: '/service-details'
    }, {
        name: 'Deep Learning',
        routerPath: '/service-details'
    }, {
        name: 'Statistical Modeling',
        routerPath: '/service-details'
    }
];
const LegalContent = [
    {
        name: 'Terms of use',
        routerPath: '/faq'
    }, {
        name: 'Terms &amp; conditions',
        routerPath: '/faq'
    }, {
        name: 'Privacy policy',
        routerPath: '/faq'
    }, {
        name: 'Cookie policy',
        routerPath: '/faq'
    }
];



const FooterFour = () => {
    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-5 footer-intro mb-40">
                    <div className="logo">
                        <Link to="/"><img src="images/logo/logo_03.png" alt="" width={130}/></Link>
                    </div>
                    <p>QuaintQuant Pty Ltd was born out of a desire to revolutionise asset management using data analysis. Our experienced team is passionate about delivering insightful analysis and strategic quantitative trading solutions to our clients. Delve into our history, meet our dedicated team, and understand our mission, vision, and values.</p>
                    {/* <ul className="d-flex social-icon style-none">
                        {SocialContent.map((val, i) => (
                            <li key={i}>
                                <a href={val.routerPath}><i className={val.icon}/></a>
                            </li>
                        ))}
                    </ul> */}
                </div>
                <div className="mt-150 col-lg-3 col-sm-6">
                    <h5 className="footer-title">Links</h5>
                    <ul className="footer-nav-link style-none">
                        {PageContent.map((val, i) => (
                            <li key={i}>
                                <HashLink to={val.routerPath}>{val.name}</HashLink>
                            </li>
                        ))}
                    </ul>
                </div>
                {/* <div className="col-lg-3 col-sm-4 mb-30">
                    <h5 className="footer-title">Services</h5>
                    <ul className="footer-nav-link style-none">
                        {ServiceContent.map((val, i) => (
                            <li key={i}>
                            <Link to={val.routerPath}>{val.name}</Link>
                        </li>
                        ))}
                       
                    </ul>
                </div> */}
                {/* <div className="col-xl-2 col-lg-3 col-sm-4 mb-30">
                    <h5 className="footer-title">Legal</h5>
                    <ul className="footer-nav-link style-none">
                        {LegalContent.map((val, i) => (
                            <li key={i}>
                            <Link to={val.routerPath}>{val.name}</Link>
                        </li>
                        ))}
                    </ul>
                </div> */}
                <div className="mt-150 col-xl-3 col-lg-3  col-sm-6 mb-30 footer-intro ">
                    <h5 className="footer-title">Contact us</h5>
                    <ul className='footer-nav-link style-none'>
                        <li>
                            <a target="_blank" href='https://www.google.com/maps/place/191+Melbourne+St,+North+Adelaide+SA+5006/@-34.9086705,138.6024754,17z/data=!3m1!4b1!4m6!3m5!1s0x6ab0c925b83bc6bd:0xb68c1043ccb47fb1!8m2!3d-34.9086705!4d138.6050503!16s%2Fg%2F11csf3rqgd?entry=ttu'>U2 / 191 Melbourne Street, North Adelaide SA 5006</a>
                            <a href='tel:+61426700961'>Phone: 0426 700 961</a>
                            <a href='mailto:info@quaintquant.com'>Email: info@quaintquant.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default FooterFour