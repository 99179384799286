import React,{Fragment} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';


import TopNavFour from '../../components/header/TopNavFour';
import HeroBannerFive from '../../components/hero-banner/HeroBannerFive';
import FancyFeatureSeventeen from '../../components/feature/FancyFeatureSeventeen';
import About from '../../components/about/About';
import FancyFeatureNineteen from '../../components/feature/FancyFeatureNineteen';
import CounterOne from '../../components/counter/CounterOne';
import TestimonialFive from '../../components/testimonial/TestimonialFive';
import Faq from '../../components/faq/Faq';
import Blog from '../../components/blog/Blog';
import Contact from '../../components/contact/Contact';
import CallToAction from '../../components/call-to-action/CallToAction';
import BrandTwo from '../../components/brand/BrandTwo';
import FooterFour from '../../components/footer/FooterFour';
import CopyRightFour from '../../components/footer/CopyRightFour';
import FancyFeatureThree from '../../components/feature/FancyFeatureThree'



const UserAnalysis = () => {
    return (
        <Fragment>
            <div className="main-page-wrapper">
                <Helmet>
                    <title>QUAINTQUANT - Data Science & Analytics</title>
                </Helmet>
                {/* helmet end */}

                <TopNavFour/>
                {/* theme-menu-four */}

                <HeroBannerFive/> 
                {/* {Herobanner End} */}
                <div id="aboutus-hash"></div>
                <About/> 
                <div id="service-hash"></div>
                <div className="fancy-feature-seventeen position-relative mt-160 xl-mt-50 mb-200" >
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-5" data-aos="fade-right">
                                <div className="title-style-three text-center text-lg-start">
                                    <h2 className="main-title">
                                        <span>Services</span> We Provide with Quality.</h2>
                                </div>
                                {/* /.title-style-three */}
                            </div>
                            <div className="col-xl-6 col-lg-7" data-aos="fade-left">
                                <p className="m0 text-center text-lg-start md-pt-30">We combine cutting-edge technology with deep industry knowledge to offer a broad spectrum of services.  Discover how we can assist you in making informed investment decisions and maximising returns.</p>
                            </div>
                        </div>
                        <FancyFeatureSeventeen/>
                    </div>
                    {/* /.container */}
                    <div className="shapes shape-one"/>
                </div>
                {/* /.fancy-feature-seventeen */}

                {/* /.fancy-feature-eighteen */}
                <div id="investmentprocess-hash"></div>
                <div className="fancy-feature-three position-relative">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <div className="block-style-two pe-xxl-5" data-aos="fade-right">
                                    <div className="title-style-one">
                                        {/* <div className="sc-title">Working Process</div> */}
                                        <h2 className="main-title">At QuaintQuant Pty Ltd</h2>
                                    </div>
                                    {/* /.title-style-one */}
                                    <p className="pt-20 pb-30 lg-pb-10">We offer a holistic approach to real estate investment, combining data analysis, project design, construction management, and quantitative trading.</p>
                                    {/* <div className="btn-three">Want to learn more about us? <Link to="/about-one">Click here <i className="fas fa-chevron-right"/></Link>
                                    </div> */}
                                </div>
                                {/* /.block-style-two */}
                            </div>
                            <div className="col-xl-6 col-lg-7 col-md-6 ms-auto text-end">
                                <div className="illustration-holder position-relative d-inline-block sm-mt-50">
                                    <img src="images/assets/ils_15.svg" alt="" className="w-100 main-illustration"/>
                                    <img src="images/assets/ils_15_1.svg" alt="" className="shapes shape-one"/>
                                    <img src="images/assets/ils_15_2.svg" alt="" className="shapes shape-two"/>
                                    <img src="images/assets/ils_15_3.svg" alt="" className="shapes shape-three"/>
                                    <img src="images/assets/ils_15_4.svg" alt="" className="shapes shape-four"/>
                                    <img
                                        src="images/assets/ils_15_5.svg"
                                        alt=""
                                        className="shapes shape-five"
                                        data-aos="fade-down"
                                        data-aos-delay={200}
                                        data-aos-duration={2000}/>
                                    <img
                                        src="images/assets/ils_15_6.svg"
                                        alt=""
                                        className="shapes shape-six"
                                        data-aos="fade-down"
                                        data-aos-delay={100}
                                        data-aos-duration={2000}/>
                                    <img
                                        src="images/assets/ils_15_7.svg"
                                        alt=""
                                        className="shapes shape-seven"
                                        data-aos="fade-down"
                                        data-aos-duration={2000}/>
                                </div>
                                {/* /.illustration-holder */}
                            </div>
                        </div>
                    </div>
                    {/* /.container */}
                    <div className="mt-100 lg-mt-70">
                        <div className="container">
                            <FancyFeatureThree/>
                        </div>
                    </div>
                </div>

                {/* <CounterOne/> 
                /Counter one end */}

                
                <div id="contact-hash"></div>
                <Contact /> {/* /.Fancy Feature 21 end */}
                
                


                <div className="footer-style-four theme-basic-footer">
                    <div className="container">
                        <div className="inner-wrapper">

                            <FooterFour/> {/* /.FooterFour End */}

                            <div className="bottom-footer">
                                <CopyRightFour/>
                            </div>
                        </div>
                        {/* /.inner-wrapper */}
                    </div>
                </div>
                {/* /.footer-style-four */}

            </div>
        </Fragment>
    )
}

export default UserAnalysis